/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import {Col, Image, Input, Row, Select, Spin, Switch, Tabs, notification} from 'antd';
import Jwt_decrypt from "common/Jwt_decrypt";
import axiosCall from "common/axios_call";
import {_get_admin, _get_driver, _get_merchant_user, _upgrade_sa, _upgrade_sl} from "common/axios_services";
import CategoryNav from 'examples/Navbars/CategoryNav';
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import {_upgrade_sm} from "common/axios_services";
import {_activate_mt} from "../../common/axios_services";


function UserDetails() {
    const {TabPane} = Tabs;

    const [loadingUser, setLoadingUser] = useState(true);
    const [loadingSwitchSl, setLoadingSwitchSl] = useState(false);
    const [loadingSwitchSm, setLoadingSwitchSm] = useState(false);
    const [loadingSwitchSa, setLoadingSwitchSa] = useState(false);
    const [loadingSwitchMt, setLoadingSwitchMt] = useState(false);
    const [userInfo, setUserInfo] = useState(false);
    const [driverInfo, setDriverInfo] = useState(false);
    const [merchantInfo, setMerchantInfo] = useState(false);
    const [adminInfo, setAdminInfo] = useState(false);
    const [becomeSl, setBecomeSl] = useState(userInfo?.user?.is_sl);
    const [becomeSa, setBecomeSa] = useState(userInfo?.user?.is_sa);
    const [becomeSm, setBecomeSm] = useState(userInfo?.user?.is_sm);
    const [updateCount, setUpdateCount] = useState(1)

    const token = Jwt_decrypt({token: localStorage.konnect_admin_token});
    const params = useParams()

    const openNotificationWithIcon = (type, title, message) => {
        notification[type]({message: title, placement: 'bottomRight', description: message, });
    };

    const fetchDriver = async ({id}) => {
        try {
            const driver = await _get_driver({id})
            setDriverInfo(driver.data.data)
        } catch (err) {
            setDriverInfo(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const fetchMerchantUser = async ({id}) => {
        try {
            const merchant = await _get_merchant_user({id})
            setMerchantInfo(merchant.data.data)
        } catch (err) {
            setMerchantInfo(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    const fetchAdmin = async ({id}) => {
        try {
            const admin = await _get_admin({id})
            setAdminInfo(admin.data.data)
        } catch (err) {
            setAdminInfo(false)
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    }

    useEffect(() => {
        setLoadingUser(true)
        axiosCall(`/user-profile/details/${params.id}`, {headers: {Authorization: `Bearer ${token}`}}).then(res => {
            setLoadingUser(false)
            setUserInfo(res.data.data);
            setBecomeSl(res.data.data.user.is_sl)
            setBecomeSa(res.data.data.user.is_sa)
        }).catch(err => {
            setLoadingUser(false)
            console.log(err);
        })
        if (params.route === "drivers") {
            fetchDriver({id: params.role_id})
        } else {
            setDriverInfo(false)
        }
        if (params.route === "merchants") {
            fetchMerchantUser({id: params.role_id})
        } else {
            setMerchantInfo(false)
        }
        if (params.route === "admins") {
            fetchAdmin({id: params.role_id})
        } else {
            setAdminInfo(false)
        }
    }, [updateCount])

    const BecomeSLSwitch = async () => {
        setLoadingSwitchSl(true);
        try {
            const upgrade_sl = await _upgrade_sl({id: userInfo?.user?.id, status: !userInfo?.user?.is_sl})
            setLoadingSwitchSl(false);
            setBecomeSl(userInfo?.user?.is_sl)
            openNotificationWithIcon('success', upgrade_sl?.data?.title, upgrade_sl?.data?.message);
        } catch (err) {
            setBecomeSl(userInfo?.user?.is_sl)
            setLoadingSwitchSl(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    };
    const BecomeSASwitch = async () => {
        setLoadingSwitchSa(true);
        try {
            const upgrade_sa = await _upgrade_sa({id: userInfo?.user?.id, status: !userInfo?.user?.is_sa})
            setLoadingSwitchSa(false);
            setBecomeSa(userInfo?.user?.is_sa)
            openNotificationWithIcon('success', upgrade_sa?.data?.title, upgrade_sa?.data?.message);
        } catch (err) {
            setBecomeSa(userInfo?.user?.is_sa)
            setLoadingSwitchSa(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    };
    const BecomeSMSwitch = async () => {
        setLoadingSwitchSm(true);
        try {
            const upgrade_sa = await _upgrade_sm({id: userInfo?.user?.id, status: !userInfo?.user?.is_sm})
            setLoadingSwitchSm(false);
            setBecomeSm(userInfo?.user?.is_sm)
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', upgrade_sa?.data?.title, upgrade_sa?.data?.message);
        } catch (err) {
            setBecomeSm(userInfo?.user?.is_sm)
            setLoadingSwitchSm(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    };
    const BecomeMTSwitch = async () => {
        setLoadingSwitchMt(true);
        try {
            const acitivate_mt = await _activate_mt({id: userInfo?.user?.id})
            setLoadingSwitchMt(false);
            setUpdateCount(updateCount + 1)
            openNotificationWithIcon('success', acitivate_mt?.data?.title, acitivate_mt?.data?.message);
        } catch (err) {
            setLoadingSwitchMt(false);
            if (err.response) {
                openNotificationWithIcon('error', err.response.data.title, err.response.data.message);
            } else {
                openNotificationWithIcon('error', "Something went wrong", err.message);
            }
        }
    };

    return (
        <DashboardLayout>
            {userInfo &&
                <CategoryNav title={`${userInfo?.user?.first_name}` + " " + `${userInfo?.user?.last_name}`} breadroute={params.route} />
            }
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info" >
                                <MDTypography variant="h6" color="white" style={{textTransform: "Capitalize"}}>
                                    {params.route}
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                <div className="">
                                    {userInfo ?
                                        <Tabs type="card">
                                            <TabPane tab="Basic Information" key="1">
                                                <form>
                                                    <div className="form-group">
                                                        <label htmlFor="profileImage">Profile Image</label><br />
                                                        <Image width={150} id="profileImage" src={userInfo?.user?.image} preview={{src: userInfo?.user?.image}} />
                                                    </div>
                                                    <div style={{marginTop: '2%'}}></div>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group space">
                                                                <label htmlFor="firstName">First name</label>
                                                                <Input disabled={true} id="firstName" value={userInfo?.user?.first_name}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="lastName">Last name</label>
                                                                <Input disabled={true} id="lastName" value={userInfo?.user?.last_name}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '2%'}}></div>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="ra">RA</label>
                                                                <Input disabled={true} id="ra" value={userInfo?.user?.is_ra}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="ra">Sex</label>
                                                                <Input disabled={true} id="sex" type="text" style={{height: '2.5rem'}} value={userInfo?.user?.gender} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '2%'}}></div>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="agegroup">Age group</label>
                                                                <Input disabled={true} id="agegroup" value={userInfo?.user?.age}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="dob">Birth Month</label>
                                                                <Input disabled={true} id="dob" value={userInfo?.user?.dob}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '2%'}}></div>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="monthly">Monthly income</label>
                                                                <Input disabled={true} id="monthly" value=""
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="phoneNumber">Phone number</label>
                                                                <PhoneInput
                                                                    disabled
                                                                    value={userInfo?.user?.phone_number}
                                                                    containerStyle={{width: '100%'}}
                                                                    inputStyle={{height: '2.5rem', width: '100%'}}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '2%'}}></div>
                                                    <Row gutter={[16, 16]}>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="emailAddress">Email address</label>
                                                                <Input disabled={true} id="emailAddress" value={userInfo?.user?.email} style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={12}>
                                                            <div className="form-group">
                                                                <label htmlFor="account_id">Account ID</label>
                                                                <Input disabled={true} id="account_id" value={userInfo?.user?.account_id} style={{height: '2.5rem'}} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '5%'}}></div>
                                                </form>
                                            </TabPane>
                                            {driverInfo &&
                                                <TabPane tab="Driver Profile" key="2">
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="driver_license_image">Driver License Image</label><br />
                                                            <Image disabled width={150} id="driver_license_image" src={driverInfo.driver_license_image} preview={{src: driverInfo.driver_license_image}} />
                                                        </div>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div className="form-flex-2">
                                                            <div className="form-group space">
                                                                <label htmlFor="merchant_name">Merchant Name</label>
                                                                <Input disabled={true} id="merchant_name" value={driverInfo.merchant_name}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div className="form-flex-2">
                                                            <div className="form-group space">
                                                                <label htmlFor="driver_license_no">Driver License Number</label>
                                                                <Input disabled={true} id="driver_license_no" value={driverInfo.driver_license_no}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: '5%'}}></div>
                                                    </form>
                                                </TabPane>
                                            }
                                            {merchantInfo &&
                                                <TabPane tab="Merchant Information" key="2">
                                                    <form>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div className="form-flex-2">
                                                            <div className="form-group space">
                                                                <label htmlFor="merchant_name">Merchant Name</label>
                                                                <Input disabled={true} id="merchant_name" value={merchantInfo.merchant_name}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div style={{marginTop: '5%'}}></div>
                                                    </form>
                                                </TabPane>
                                            }
                                            {adminInfo &&
                                                <TabPane tab="Admin Profile" key="2">
                                                    <form>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div className="form-flex-2">
                                                            <div className="form-group space">
                                                                <label htmlFor="location">Location</label>
                                                                <Input disabled={true} id="location" value={adminInfo.location}
                                                                    type="text" style={{height: '2.5rem'}} />
                                                            </div>
                                                        </div>
                                                        <div style={{marginTop: '2%'}}></div>
                                                        <div style={{marginTop: '5%'}}></div>
                                                    </form>
                                                </TabPane>
                                            }
                                            <TabPane tab="Role" key="3">
                                                <form>
                                                    <div className="form-flex-2">
                                                        <div className="space w-full">
                                                            <label htmlFor="roleName">Role Name</label>
                                                            <Input disabled={true} id="roleName" value={userInfo?.user?.role.role_name} type="text" className="h-10 w-full" />
                                                        </div>
                                                        <div className="w-full">
                                                            <label htmlFor="permission">Permission</label>
                                                            <Input disabled={true} id="permission" value={userInfo?.user?.role.position} type="text" className="h-10 w-full" />
                                                        </div>
                                                    </div>
                                                    <Row gutter={[16, 16]} style={{marginTop: "10px"}}>
                                                        {/* Former roles */}
                                                        {/* <>
                                                            <Col xs={24} md={8}>
                                                                <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                    <label htmlFor="SA">SL</label>
                                                                    <div>
                                                                        {userInfo?.user?.is_sa || userInfo?.user?.is_so ?
                                                                            <Switch checked={becomeSl} disabled /> :
                                                                            loadingSwitchSl ?
                                                                                <Switch checked={becomeSl} loading /> :
                                                                                <Switch checked={becomeSl} onChange={BecomeSLSwitch} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                    <label htmlFor="SA">SA</label>
                                                                    <div>
                                                                        {loadingSwitchSa ?
                                                                            <Switch checked={userInfo?.user?.is_sa} loading /> :
                                                                            <Switch checked={userInfo?.user?.is_sa} onChange={BecomeSASwitch} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col xs={24} md={8}>
                                                                <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                    <label htmlFor="SA">SO</label>
                                                                    <div>
                                                                        <Switch defaultChecked={userInfo?.user?.is_so} disabled />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </> */}

                                                        {/* Current role */}
                                                        <Col xs={24} md={8}>
                                                            <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                <label>RT</label>
                                                                <div>
                                                                    {/* {loadingSwitchSm ? */}
                                                                    {/* <Switch disabled checked={userInfo?.is_restaurant} loading /> : */}
                                                                    <Switch disabled checked={userInfo?.user?.is_restaurant} onChange={BecomeSMSwitch} />
                                                                    {/* } */}
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={8}>
                                                            <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                <label>SM</label>
                                                                <div>
                                                                    {loadingSwitchSm ?
                                                                        <Switch checked={userInfo?.user?.is_sm} loading /> :
                                                                        <Switch checked={userInfo?.user?.is_sm} onChange={BecomeSMSwitch} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col xs={24} md={8}>
                                                            <div className="form-group" style={{display: "flex", flexDirection: "column"}}>
                                                                <label>MT</label>
                                                                <div>
                                                                    {loadingSwitchMt ?
                                                                        <Switch checked={userInfo?.user?.is_mt} loading /> :
                                                                        <Switch checked={userInfo?.user?.is_mt} onChange={BecomeMTSwitch} />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <div style={{marginTop: '5%'}}></div>
                                                </form>
                                            </TabPane>
                                            <TabPane tab="Security settings" key="4">
                                                <div className="accountProtection">
                                                    <form>
                                                        <div className="form-group">
                                                            <label htmlFor="">Reference code:</label>
                                                            <Input disabled={true} id="refCode" value={userInfo?.user?.ref_code}
                                                                type="text" style={{height: '2.5rem'}} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label htmlFor="">Memorable word:</label>
                                                            <Input disabled={true} id="memorableWord" value={userInfo?.user?.memorable_word}
                                                                type="text" style={{height: '2.5rem'}} />
                                                        </div>
                                                    </form>
                                                    <div style={{marginTop: "5%"}} />
                                                </div>
                                            </TabPane>
                                        </Tabs>
                                        : <Spin></Spin>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default UserDetails;
