/* eslint-disable */

/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Drivers Portal (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import {DeleteOutlined, EyeOutlined, RedoOutlined} from '@ant-design/icons';
import {Button, Form, Input, Modal, Pagination, Radio, Select, Spin, Table, notification} from "antd";
import {_delete_user, _delete_users, _get_users, _get_users_metrix, _resend_users, _resend_verify_user} from "common/axios_services";
import moment from "moment";
import {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {_send_bulk_sms} from "common/axios_services";

function Users() {
    const [form] = Form.useForm();
    const [metrix, setMetrix] = useState(null);
    const [selectedValue, setSelectedValue] = useState("");
    const [page, setPage] = useState(1);
    const [total_users, setTotal_users] = useState(0);
    const [page_size, setPage_size] = useState(10);
    const [allUsersFetched, setAllUsersFetched] = useState(false);
    const [userData, setUserData] = useState(null);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteUsersModal, setDeleteUsersModal] = useState(false);
    const [resendUsersModal, setResendUsersModal] = useState(false);
    const [resendModal, setResendModal] = useState(false);
    const [arrayToDisplay, setArrayToDisplay] = useState([]);
    const [displaySwitch, setDisplaySwitch] = useState("view");
    const [query, setQuery] = useState("");
    const [filterOptions, setFilterOptions] = useState("all");
    const navigate = useNavigate()
    const [filterDate, setFilterDate] = useState("");
    const [tier, setTier] = useState("");
    const [filterToDate, setFilterToDate] = useState(moment().format('YYYY-MM-DD'));
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [updateCount, setUpdateCount] = useState(0);
    const [sendUserSmsModal, setSendUserSmsModal] = useState(false);
    const [smsMessage, setSmsMessage] = useState('');

    const openNotificationWithIcon = (type, message) => {
        notification[type]({message: '', placement: 'bottomRight', description: message, });
    };

    const getUserMetrix = async () => {
        try {
            const get_users_metrix = await _get_users_metrix({filterDate, filterToDate})
            setMetrix(get_users_metrix.data.data);
        } catch (err) {
            setMetrix(null);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const getUser = async () => {
        setAllUsersFetched(false);
        try {
            const get_users = await _get_users({page, page_size, category: filterOptions, query, verified: selectedValue, filterDate, filterToDate, tier})
            setAllUsersFetched(true);
            setTotal_users(get_users.data.meta.total);
            setArrayToDisplay(get_users.data.data);
        } catch (err) {
            setAllUsersFetched(true);
            setArrayToDisplay([]);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const handleSelectChange = (value) => {
        setSelectedValue(value);
        if (value === 0) {
            setFilterOptions('all')
        }
    };
    const handleSelectTierChange = (value) => {
        setTier(value)
    };
    useEffect(() => {
        getUserMetrix()
    }, [filterDate, filterToDate])
    useEffect(() => {
        getUser()
    }, [page, page_size, query, filterOptions, selectedValue, userData, filterDate, filterToDate, updateCount, tier])

    const data = [];
    arrayToDisplay.forEach((user, index) => {
        let phoneNumber = user.phone_number;
        if (!phoneNumber.startsWith(user.phone_code)) {
            phoneNumber = user.phone_code + phoneNumber;
        }

        data.push({
            key: index,
            sn: index + 1,
            id: user?.id,
            dateCreated: user.created_at.split('T')[0],
            refCode: user.ref_code,
            firstName: user.first_name,
            lastName: user.last_name,
            email: user.email,
            phoneNumber: phoneNumber,
            lga: user.lga,
            address: user.home_address,
            action: selectedValue === 0 ?
                <div style={{fontSize: '18px'}}>
                    <Link to={`/users/${user.id}/${user.id}/users`} style={{color: 'teal'}}><EyeOutlined /></Link> |
                    <RedoOutlined style={{marginLeft: "5px", cursor: "pointer", color: "Green"}} onClick={() => {setResendModal(true); setUserData(user)}} /> |
                    <DeleteOutlined style={{marginLeft: "5px", cursor: "pointer", color: "red"}} onClick={() => {setDeleteModal(true); setUserData(user)}} />
                </div>
                : <Link to={`/users/${user.id}/${user.id}/users`}>View</Link>
        })
    });

    const columns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Signup date',
            dataIndex: 'dateCreated',
        },
        {
            title: 'Ref Code',
            dataIndex: 'refCode',
        },
        {
            title: 'First name',
            className: 'column-number',
            dataIndex: 'firstName',
            align: 'center',
        },
        {
            title: 'Last name',
            className: 'column-number',
            dataIndex: 'lastName',
            align: 'center',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Phone number',
            className: 'column-number',
            dataIndex: 'phoneNumber',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];
    const soColumns = [
        {
            title: 'SN',
            dataIndex: 'sn',
        },
        {
            title: 'Signup date',
            dataIndex: 'dateCreated',
        },
        {
            title: 'Ref Code',
            dataIndex: 'refCode',
        },
        {
            title: 'First name',
            className: 'column-number',
            dataIndex: 'firstName',
            align: 'center',
        },
        {
            title: 'Last name',
            className: 'column-number',
            dataIndex: 'lastName',
            align: 'center',
        },
        {
            title: 'Email',
            className: 'column-number',
            dataIndex: 'email',
            align: 'center',
        },
        {
            title: 'Phone number',
            className: 'column-number',
            dataIndex: 'phoneNumber',
            align: 'center',
        },
        {
            title: 'LGA',
            className: 'column-number',
            dataIndex: 'lga',
            align: 'center',
        },
        {
            title: 'Address',
            className: 'column-number',
            dataIndex: 'address',
            align: 'center',
        },
        {
            title: 'Action',
            className: 'column-number',
            dataIndex: 'action',
            align: 'center'
        }
    ];

    const change_page = (selected_page, pageSize) => {
        setPage(selected_page);
        setPage_size(pageSize);
        setAllUsersFetched(false);
        setArrayToDisplay([]);
    }

    function dataFilter({search}) {
        setQuery(search.toLowerCase())
        setAllUsersFetched(false)
    }

    const onChange = (value) => {
        setFilterOptions(value)
    }
    const deleteUser = async () => {
        setConfirmLoading(true);
        try {
            const delete_user = await _delete_user({id: userData.id})
            setConfirmLoading(false);
            openNotificationWithIcon('success', delete_user.data.message)
            setDeleteModal(false)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const resendUser = async () => {
        setConfirmLoading(true);
        try {
            const resend = await _resend_verify_user({id: userData.id})
            setConfirmLoading(false);
            setResendModal(false)
            openNotificationWithIcon('success', resend.data.message)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }

    const onChangeDate = (e) => setFilterDate(e.target.value);
    const onChangeToDate = (e) => setFilterToDate(e.target.value);

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const deleteUsers = async () => {
        const selectedRows = selectedRowKeys.map(id => ({id}));
        setConfirmLoading(true);
        try {
            const delete_users = await _delete_users({selectedRows})
            setConfirmLoading(false);
            openNotificationWithIcon('success', delete_users?.data?.message)
            setDeleteUsersModal(false)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    }
    const resendUsers = async () => {
        const selectedRows = selectedRowKeys.map(id => ({id}));
        setConfirmLoading(true);
        try {
            const resend_users = await _resend_users({selectedRows})
            setConfirmLoading(false);
            openNotificationWithIcon('success', resend_users?.data?.message)
            setResendUsersModal(false)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };
    const sendSms = async () => {
        const selectedIds = selectedRowKeys.join(',');
        setConfirmLoading(true);
        try {
            const resend_sms = await _send_bulk_sms({message: smsMessage, user_ids: selectedIds})
            setConfirmLoading(false);
            openNotificationWithIcon('success', resend_sms?.data?.message)
            setSendUserSmsModal(false)
            setUpdateCount(updateCount + 1)
        } catch (err) {
            setConfirmLoading(false);
            if (err.response) {
                if (err.response.data.message === "Unauthorized") {
                    navigate('/')
                    openNotificationWithIcon('error', err.response.data.message)
                } else {
                    openNotificationWithIcon('error', err.response.data.message)
                }
            } else {
                openNotificationWithIcon('error', err.message)
            }
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        getCheckboxProps: (record) => ({
            value: record?.id,
        }),
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="success"
                            icon="store"
                            title="Normal Users"
                            count={`${metrix?.total_normal_user ? metrix?.total_normal_user : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            icon="leaderboard"
                            title="Restaurant"
                            count={`${metrix?.total_food_vendor ? metrix?.total_food_vendor : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="success"
                            icon="store"
                            title="Marketing"
                            count={`${metrix?.marketing_users ? metrix?.marketing_users : 0}`}
                        />
                    </MDBox>
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="primary"
                            icon="person_add"
                            title="Not Verified"
                            count={`${metrix?.total_email_not_verified ? metrix?.total_email_not_verified : 0}`}
                        />
                    </MDBox>
                </Grid>
            </Grid>

            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox mx={2} mt={-3} py={3} px={2} variant="gradient" bgColor="info" borderRadius="lg" coloredShadow="info">
                                <MDTypography variant="h6" color="white">
                                    Users
                                </MDTypography>
                            </MDBox>
                            <MDBox pt={3} px={2}>
                                {displaySwitch === "view" &&
                                    <div className="flex flex-col md:flex-row items-center gap-3 md:gap-0 md:justify-between">
                                        <label>Filter Users data</label>
                                        <Form layout="vertical" form={form} initialValues={{search: ""}} onValuesChange={dataFilter} style={{maxWidth: 600, minWidth: 300}}>
                                            <Form.Item name="search" className="mb-0">
                                                <Input placeholder="search by first name" className="mb-0" />
                                            </Form.Item>
                                        </Form>
                                    </div>
                                }
                                <div className="flex justify-between items-center mb-3 gap-3">
                                    <Select placeholder="Filter User" onChange={handleSelectChange} value={selectedValue} style={{width: 200}}>
                                        <Select.Option value="">All users</Select.Option>
                                        <Select.Option value={1}>Verified</Select.Option>
                                        <Select.Option value={0}>Not Verified</Select.Option>
                                    </Select>
                                    <Select placeholder="Filter User" onChange={handleSelectTierChange} value={tier} style={{width: 200}}>
                                        <Select.Option value="">All Tiers</Select.Option>
                                        <Select.Option value="tier_0">Tier 0</Select.Option>
                                        <Select.Option value="tier_1">Tier 1</Select.Option>
                                        <Select.Option value="tier_2">Tier 2</Select.Option>
                                    </Select>
                                    {selectedValue === 0 && (
                                        <Button type="primary" onClick={() => setDeleteUsersModal(true)} disabled={selectedRowKeys.length === 0}>Delete Selected</Button>
                                    )}
                                    <Button type="primary" onClick={() => setSendUserSmsModal(true)} disabled={selectedRowKeys.length === 0}>Send Sms</Button>
                                    {selectedValue === 0 && (
                                        <Button type="primary" onClick={() => setResendUsersModal(true)} disabled={selectedRowKeys.length === 0}>Resend Email</Button>
                                    )}
                                    {selectedValue !== 0 && (
                                        <div>
                                            <Select defaultValue="all" onChange={onChange} style={{width: 200}}>
                                                <Select.Option value="all">All</Select.Option>
                                                <Select.Option value="sm">SM</Select.Option>
                                                <Select.Option value="rt">RT</Select.Option>
                                                <Select.Option value="mt">MT</Select.Option>
                                            </Select>
                                            {/* <Radio.Group onChange={onChange} defaultValue="all">
                                                <Radio.Button value="all">All</Radio.Button>
                                                <Radio.Button value="ra">SM</Radio.Button>
                                                <Radio.Button value="so">RT</Radio.Button>
                                                <Radio.Button value="mt">MT</Radio.Button>
                                            </Radio.Group> */}
                                        </div>
                                    )}
                                    <div className="grid-flex" style={{fontSize: "14px"}}>
                                        <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>From</p>
                                        <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} type="date" defaultValue={filterDate} name="date" max={moment().format('YYYY-MM-DD')} onChange={onChangeDate} />
                                    </div>
                                    <div className="grid-flex" style={{fontSize: "14px"}}>
                                        <p style={{marginBottom: "0px", color: "rgba(0, 0, 0, 0.88)"}}>To</p>
                                        <input id="date" style={{width: "100%", padding: "0px 11px", color: "rgba(0, 0, 0, 0.88)", height: "30px", borderRadius: "6px", border: "1px solid rgba(0, 0, 0, 0.88)"}} max={moment().format('YYYY-MM-DD')} type="date" defaultValue={filterToDate} name="date" onChange={onChangeToDate} />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {allUsersFetched ?
                                        <>
                                            {(filterOptions === "sm" || filterOptions === "all" || filterOptions === "mt" || filterOptions === "rt") &&
                                                <>
                                                    {/* {(selectedValue === 0 || selectedValue === 1) ? */}
                                                    <Table rowSelection={rowSelection} rowKey="id" columns={columns} pagination={false} dataSource={data} bordered />
                                                    {/* <Table columns={columns} pagination={false} dataSource={data} bordered /> */}
                                                    {/* } */}
                                                </>
                                            }
                                            {filterOptions === "ra" &&
                                                <Table columns={columns} pagination={false} dataSource={data} bordered />
                                            }
                                            {filterOptions === "so" &&
                                                <Table columns={soColumns} pagination={false} dataSource={data} bordered />
                                            }
                                            <div style={{marginTop: "2%"}}></div>
                                            <Pagination showSizeChanger current={page} total={total_users} showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} users`} defaultPageSize={page_size} onChange={change_page} />
                                            <div style={{marginTop: "2%"}}></div>
                                        </>
                                        :
                                        <div className="">
                                            <Spin />
                                        </div>
                                    }
                                </div>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
            <Modal title="Send SMS" open={sendUserSmsModal} okButtonProps={{style: {backgroundColor: 'red', borderColor: 'red'}, disabled: !smsMessage.trim()}} onOk={sendSms} confirmLoading={confirmLoading} centered onCancel={() => setSendUserSmsModal(false)}>
                <Input style={{width: "100%"}} placeholder="Enter your SMS message" value={smsMessage} onChange={(e) => setSmsMessage(e.target.value)} />
                <p>Are you sure you want to send this sms to the {selectedRowKeys.length} selected account(s)?</p>
            </Modal>
            <Modal title="Delete Users Modal" open={deleteUsersModal} onOk={deleteUsers} confirmLoading={confirmLoading} okButtonProps={{style: {backgroundColor: 'red', borderColor: 'red'}}} centered onCancel={() => setDeleteUsersModal(false)}>
                <p>Are you sure you want to delete the {selectedRowKeys.length} selected account(s)? This action is IRREVERSIBLE</p>
            </Modal>
            <Modal title="Resend Users Modal" open={resendUsersModal} onOk={resendUsers} okText="Resend" confirmLoading={confirmLoading} centered onCancel={() => setResendUsersModal(false)}>
                <p>Are you sure you want to resend mail to the {selectedRowKeys.length} selected account(s)?</p>
            </Modal>
            <Modal title="Delete User Modal" open={deleteModal} onOk={deleteUser} confirmLoading={confirmLoading} okButtonProps={{style: {backgroundColor: 'red', borderColor: 'red'}}} centered onCancel={() => setDeleteModal(false)}>
                <p>Are you sure you want to delete <b>{userData?.first_name} {userData?.last_name}</b> account? This action is IRREVERSIBLE</p>
            </Modal>
            <Modal title="Resend User Modal" open={resendModal} onOk={resendUser} okText="Resend" confirmLoading={confirmLoading} centered onCancel={() => setResendModal(false)}>
                <p>Are you sure you want to resend mail to <b>{userData?.first_name} {userData?.last_name}</b>?</p>
            </Modal>

        </DashboardLayout>
    );
}

export default Users;